import { gql, useMutation } from "@apollo/client";

const deleteCourseMutation = gql`
  mutation DeleteCourse($id: ID!) {
    deleteCourse(id: $id)
  }
`;

export const useDeleteCourse = () => {
  return useMutation(deleteCourseMutation, { refetchQueries: ["courses"] });
};
