import capitalize from "lodash/capitalize";
import React, { useCallback, useState } from "react";
import { ChevronDown } from "react-feather";
import { useEditCourse } from "../../../data/mutations/useEditCourse";
import { CourseItem, Status } from "../../../types";
import { Button } from "../Button";
import { Dialog } from "../Dialog";
import { Fieldset } from "../Fieldset";
import { Form } from "../Form";
import { Label } from "../Label";
import { Select } from "../Select";
import { Textarea } from "../Textarea";
import { TextInput } from "../TextInput";

type EditCourseDialogProps = {
  course: CourseItem;
  open: boolean;
  onOpenChange: (value: boolean) => void;
};

export const EditCourseDialog = ({ course, open, onOpenChange }: EditCourseDialogProps) => {
  const [courseName, setCourseName] = useState<string>(course.name);
  const [courseDescription, setCourseDescription] = useState<string>(course.description);
  const [courseStatus, setCourseStatus] = useState<Status>(course.status);
  const [editCourse] = useEditCourse();

  const onCourseEdit = useCallback(() => {
    return editCourse({
      variables: {
        id: course.id,
        description: courseDescription,
        name: courseName,
        status: courseStatus,
      },
    });
  }, [editCourse, course.id, courseName, courseDescription, courseStatus]);

  return (
    <Dialog.Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.Title>Edit course</Dialog.Title>
        <Dialog.Description>{`Click save when you're done.`}</Dialog.Description>
        <div className={"mt-5"}>
          <Form>
            <Fieldset>
              <Label htmlFor="name">Name</Label>
              <TextInput
                id="name"
                value={courseName}
                onValueChange={(e) => {
                  setCourseName(e.currentTarget.value);
                }}
              />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="description">Description</Label>
              <Textarea
                id="description"
                value={courseDescription}
                onValueChange={(e) => {
                  setCourseDescription(e.currentTarget.value);
                }}
              />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="status">Status</Label>
              <Select.Select
                value={courseStatus}
                onValueChange={(value) => setCourseStatus(value as Status)}>
                <Select.Trigger ariaLabel="status">
                  <Select.Value>{capitalize(courseStatus)}</Select.Value>
                  <Select.Icon>
                    <ChevronDown />
                  </Select.Icon>
                </Select.Trigger>
                <Select.Content>
                  <Select.Viewport>
                    <Select.Item value={Status.draft}>
                      <Select.ItemText>Draft</Select.ItemText>
                    </Select.Item>
                    <Select.Item value={Status.published}>
                      <Select.ItemText>Published</Select.ItemText>
                    </Select.Item>
                  </Select.Viewport>
                </Select.Content>
              </Select.Select>
            </Fieldset>
          </Form>
        </div>
        <Dialog.Footer>
          <Dialog.Close>
            <Button
              variant="primary"
              text="Save course"
              onClick={() => {
                onCourseEdit();
              }}
            />
          </Dialog.Close>
          <Dialog.Close>
            <Button variant="outline" text="Cancel" />
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Dialog>
  );
};
