import React from "react";
import { Card } from "../Card";
import { Skeleton } from "../Skeleton";

export const CourseCardSkeleton = () => {
  return (
    <Skeleton.Group>
      <Card>
        <div className={"flex items-center justify-between"}>
          <div className={"flex items-center justify-center gap-3"}>
            <Skeleton.Skeleton>
              <h2 className="text-xl">███████ ████ ███████</h2>
            </Skeleton.Skeleton>
            <Skeleton.Skeleton borderRadius="rounded-full">
              <p className={"text-sm leading-tight text-gray-200"}>█ ████████</p>
            </Skeleton.Skeleton>
          </div>

          <Skeleton.Skeleton height="7" width="7" />
        </div>
        <div className={"pt-3"}>
          <Skeleton.Skeleton>
            <p className={"text-sm leading-tight text-gray-200 "}>
              ███████ ████ ███ █████ ████ ████ ███ ███ ███ ███ ██████ ████
            </p>
          </Skeleton.Skeleton>
        </div>
      </Card>
    </Skeleton.Group>
  );
};
