import clsx from "clsx";
import React from "react";

type FieldsetProps = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
};

export const Fieldset = ({ children, className }: FieldsetProps) => {
  return (
    <fieldset className={clsx("mb-1 flex flex-1 flex-col gap-2", className)}>{children}</fieldset>
  );
};
