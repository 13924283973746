import Link from "next/link";
import React, { useState } from "react";
import { MoreHorizontal } from "react-feather";
import { CourseItem } from "../../../types";
import { pluralize } from "../../../utils";
import { Badge } from "../Badge";
import { DropdownMenu } from "../DropdownMenu";
import { IconButton } from "../IconButton";
import { DeleteCourseDialog } from "./DeleteCourseDialog";
import { EditCourseDialog } from "./EditCourseDialog";

type CourseCardProps = {
  course: CourseItem;
};

export const CourseCard = ({ course }: CourseCardProps) => {
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
  const [isEditCourseDialogOpen, setIsEditCourseDialogOpen] = useState(false);
  const [isDeleteCourseDialogOpen, setIsDeleteCourseDialogOpen] = useState(false);

  return (
    <>
      <Link href={`/courses/${course.slug}`} key={course.id} passHref legacyBehavior>
        <a
          className={
            "flex cursor-pointer flex-col rounded-md border-[1px] border-gray-300 bg-gray-50 p-4 transition duration-standard ease-in-out hover:border-gray-400 hover:bg-white"
          }>
          <div className={"flex items-center justify-between"}>
            <div className={"flex items-center justify-center gap-3"}>
              <h2 className="leading-0 text-lg">{course.name}</h2>
              <Badge>{pluralize(course.sessions.data.length, "Session")}</Badge>

              {course.status === "draft" ? <Badge>{course.status.toUpperCase()}</Badge> : null}
            </div>

            <DropdownMenu.DropdownMenu
              open={isDropdownMenuOpen}
              onOpenChange={setIsDropdownMenuOpen}>
              <DropdownMenu.Trigger>
                <IconButton>
                  <MoreHorizontal />
                </IconButton>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                <DropdownMenu.Item onSelect={() => setIsEditCourseDialogOpen(true)}>
                  Edit
                </DropdownMenu.Item>
                <DropdownMenu.Item onSelect={() => setIsDeleteCourseDialogOpen(true)}>
                  Delete
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.DropdownMenu>
          </div>
          <div className={"pt-3"}>
            <p className={"text-sm leading-tight text-gray-600"}>{course.description}</p>
          </div>
        </a>
      </Link>

      <EditCourseDialog
        course={course}
        open={isEditCourseDialogOpen}
        onOpenChange={setIsEditCourseDialogOpen}
      />

      <DeleteCourseDialog
        course={course}
        open={isDeleteCourseDialogOpen}
        onOpenChange={setIsDeleteCourseDialogOpen}
      />
    </>
  );
};
