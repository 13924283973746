import { gql, useQuery } from "@apollo/client";
import { CourseItem } from "../../types";

interface CoursesQueryResponse {
  data: CourseItem[];
  cursor: string;
}

const query = gql`
  query courses($clinicId: ID) {
    # TODO: handle pagination properly
    courses(clinicId: $clinicId, count: 200) {
      data {
        id
        slug
        name
        description
        status
        sessions {
          cursor
          data {
            id
            name
          }
        }
      }
    }
  }
`;

export function useCourses(clinicId?: string) {
  return useQuery<{ courses: CoursesQueryResponse }, { clinicId?: string }>(query, {
    variables: { clinicId },
  });
}
