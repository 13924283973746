import clsx from "clsx";
import React from "react";

type CardProps = {
  children: React.ReactNode;
  className?: string;
  borderWidth?: string;
  padding?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Card = ({
  children,
  className,
  borderWidth = "1px",
  padding = "4",
  ...rest
}: CardProps) => {
  return (
    <div
      {...rest}
      className={clsx(
        `flex flex-col rounded-lg border-[${borderWidth}] border-gray-300 bg-gray-50 p-${padding}`,
        className,
      )}>
      {children}
    </div>
  );
};
