import React from "react";

type SkeletonGroupProviderProps = {
  children: JSX.Element | JSX.Element[];
  isLoading?: boolean;
};

export const SkeletonGroupContext = React.createContext<boolean | undefined>(undefined);

export const SkeletonGroup = ({ children, isLoading = true }: SkeletonGroupProviderProps) => {
  return (
    <SkeletonGroupContext.Provider value={isLoading}>{children}</SkeletonGroupContext.Provider>
  );
};
