import jwtDecode from "jwt-decode";
import { NextApiRequestCookies } from "next/dist/server/api-utils";
import { getAuthToken, getRefreshToken } from "src/backend/helpers/authToken";
import { LimbicUser } from "src/types/user";

export const getMeFromCookie = (cookies: NextApiRequestCookies) => {
  if (!cookies.accessToken || !cookies.refreshToken) {
    return null;
  }

  return jwtDecode(cookies.accessToken) as LimbicUser;
};

export const getMeFromLocalStorage = () => {
  if (typeof window === "undefined") return null;

  const accessToken = getAuthToken();
  const refreshToken = getRefreshToken();

  if (!accessToken || !refreshToken) {
    return null;
  }

  return jwtDecode(accessToken) as LimbicUser;
};
