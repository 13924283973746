import { gql, useMutation } from "@apollo/client";
import { Status } from "../../types";

interface EditCourseMutationVariables {
  id: string;
  name?: string;
  description?: string;
  status?: Status;
}

const editCourseMutation = gql`
  mutation EditCourse($id: ID!, $name: String, $description: String, $status: CourseStatus) {
    editCourse(id: $id, name: $name, description: $description, status: $status) {
      id
    }
  }
`;

export const useEditCourse = () => {
  return useMutation<{ id: string }, EditCourseMutationVariables>(editCourseMutation, {
    refetchQueries: ["courses", "course"],
  });
};
