import clsx from "clsx";
import React, { forwardRef } from "react";

type FormProps = {
  onSubmit?: (event: React.FormEvent<HTMLElement>) => void;
  children: React.ReactNode | React.ReactNode[];
  gap?: number;
};

export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ onSubmit, children, gap = 2 }, forwardedRef) => {
    return (
      <form
        className={clsx("flex flex-col", `gap-${gap}`)}
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit?.(event);
        }}
        ref={forwardedRef}>
        {children}
      </form>
    );
  },
);
