import { gql, useMutation } from "@apollo/client";

const addCourseMutation = gql`
  mutation AddCourse($name: String!, $description: String!) {
    addCourse(name: $name, description: $description) {
      id
      slug
    }
  }
`;

export const useAddCourse = () => {
  return useMutation(addCourseMutation, { refetchQueries: ["courses"] });
};
