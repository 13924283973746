import React, { useCallback, useState } from "react";
import { useAddCourse } from "../../../data/mutations/useAddCourse";
import { Button } from "../Button";
import { Dialog } from "../Dialog";
import { Fieldset } from "../Fieldset";
import { Form } from "../Form";
import { Label } from "../Label";
import { Textarea } from "../Textarea";
import { TextInput } from "../TextInput";

type CreateCourseDialogProps = {
  open: boolean;
  onOpenChange: (value: boolean) => void;
};

export const CreateCourseDialog = ({ open, onOpenChange }: CreateCourseDialogProps) => {
  const [addCourse] = useAddCourse();
  const [courseName, setCourseName] = useState<string>("");
  const [courseDescription, setCourseDescription] = useState<string>("");

  const onCourseSave = useCallback(
    (courseName, courseDescription) => {
      addCourse({ variables: { name: courseName, description: courseDescription } });
    },
    [addCourse],
  );

  const emptyCourseFormData = useCallback(() => {
    setCourseName("");
    setCourseDescription("");
  }, []);

  return (
    <Dialog.Dialog
      open={open}
      onOpenChange={() => {
        emptyCourseFormData();
        onOpenChange(!open);
      }}>
      <Dialog.Content>
        <Dialog.Title>Create course</Dialog.Title>
        <Dialog.Description>{`Create a new course. Click save when you're done.`}</Dialog.Description>
        <div className={"mt-5"}>
          <Form>
            <Fieldset>
              <Label htmlFor="name">Name</Label>
              <TextInput
                id="name"
                value={courseName}
                onValueChange={(e) => {
                  setCourseName(e.currentTarget.value);
                }}
              />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="description">Description</Label>
              <Textarea
                id="description"
                value={courseDescription}
                onValueChange={(e) => {
                  setCourseDescription(e.currentTarget.value);
                }}
              />
            </Fieldset>
          </Form>
        </div>
        <Dialog.Footer>
          <Dialog.Close>
            <Button
              variant="primary"
              text="Save course"
              onClick={() => {
                onCourseSave(courseName, courseDescription);
                emptyCourseFormData();
              }}
            />
          </Dialog.Close>
          <Dialog.Close>
            <Button
              variant="plain"
              text="Cancel"
              onClick={() => {
                emptyCourseFormData();
              }}
            />
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Dialog>
  );
};
