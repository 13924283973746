import clsx from "clsx";
import { ReactNode } from "react";
import { X } from "react-feather";

type BadgeProps = {
  color?: "gray" | "white";
  onRemoveClick?: (key: number) => void;
  option?: {
    icon: ReactNode;
    onClick: (ref: any) => void;
  };
  children: string;
  id?: number;
  className?: string;
};

export const Badge = ({
  color = "gray",
  onRemoveClick,
  id,
  children,
  className,
  option,
}: BadgeProps) => {
  if (onRemoveClick) option = { icon: <X />, onClick: onRemoveClick };

  return (
    <span
      className={clsx(
        "leading-0 inline-flex flex-shrink-0 select-none items-center rounded-full px-2 text-xs",
        color === "gray" && "border border-gray-100 bg-gray-100 text-gray-600",
        color === "white" && "border-[1px] border-gray-300 bg-white text-gray-600",
        className,
      )}>
      {children}
      {option ? (
        <button
          className={clsx(
            "ml-0.5 -mr-[4px] inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full p-[1px] transition-all duration-standard ease-in-out ",
            color === "gray" &&
              "bg-transparent text-gray-500 hover:bg-gray-100 hover:text-gray-600",
            color === "white" &&
              "bg-transparent text-gray-500 hover:bg-gray-100 hover:text-gray-600",
          )}
          onClick={() => option?.onClick(id ? id : 0)}>
          {option.icon}
        </button>
      ) : null}
    </span>
  );
};
