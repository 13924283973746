import Router from "next/router";
import React, { useCallback } from "react";
import { useDeleteCourse } from "../../../data/mutations/useDeleteCourse";
import { CourseItem } from "../../../types";
import { Button } from "../Button";
import { Dialog } from "../Dialog";

type DeleteCourseDialogProps = {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  course: CourseItem;
};

export const DeleteCourseDialog = ({ course, open, onOpenChange }: DeleteCourseDialogProps) => {
  const [deleteCourse] = useDeleteCourse();

  const onCourseDelete = useCallback(() => {
    deleteCourse({ variables: { id: course.id } });
    Router.push("/courses");
  }, [course.id, deleteCourse]);

  return (
    <Dialog.Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.Title>Delete course</Dialog.Title>
        <Dialog.Description>
          Are you sure you want to delete this course? This action cannot be undone.
        </Dialog.Description>
        <Dialog.Footer>
          <Dialog.Close>
            <Button
              variant="primary"
              text="Delete course"
              onClick={() => {
                onCourseDelete();
              }}
            />
          </Dialog.Close>
          <Dialog.Close>
            <Button variant="ghost" text="Cancel" />
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Dialog>
  );
};
