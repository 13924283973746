import clsx from "clsx";
import React, { useContext } from "react";
import { SkeletonGroupContext } from "./SkeletonGroup";

type SkeletonItemProps = {
  as?: "div" | "span";
  background?: string;
  borderRadius?: string;
  height?: string;
  loading?: boolean;
  maxWidth?: string;
  width?: string;
};

export const SkeletonItem = ({
  as,
  background = "bg-gray-200",
  borderRadius = "rounded-md",
  children,
  height = "6",
  loading,
  maxWidth,
  width = "fit",
}: React.PropsWithChildren<SkeletonItemProps>) => {
  const groupLoading = useContext(SkeletonGroupContext);
  const active = loading ?? groupLoading;

  const Container: React.FC = ({ children }) =>
    !as || as === "div" ? (
      <div
        className={clsx(
          active &&
            `${background} text-gray-200 ${borderRadius} h-${height} max-w-${maxWidth} w-${width}`,
        )}>
        {children}
      </div>
    ) : (
      <span
        className={clsx(
          active &&
            `${background} text-gray-200 ${borderRadius} h-${height} max-w-${maxWidth} w-${width}`,
        )}>
        {children}
      </span>
    );

  return (
    <Container>
      <span className={clsx("block", active ? "visible" : "")}>{children}</span>
    </Container>
  );
};
